import {
    React,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import { SignatureBox } from '../controls/esignature.jsx';
import {
    getSignatureToken,
    prepareDocuments,
    requestSignatureAudit,
    saveSession,
} from '../libs/api.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n.js';
import { scrollUp } from '../libs/ui';

//asCurrency = ({ amount
export const Consent = () => {

    const [checklist, setChecklist] = useState('not started');
    const [ceremonyStage, setCeremonyStage] = useState('not started');
    const [saved, setSaved] = useState(false);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);

    let navigate = useNavigate();
    //let location = useLocation();


    useEffect(() => {


        scrollUp();
        setLayout({
            screenReady: true,
            currentScreen: 'consent'
        });


        saveSession({
            quote: session, token: session.token, next: (data) => {
                if (!saved) {
                    //.info("Our session was updated...", data);
                    setSaved(true);
                }
            }
        });

    }, []);


    useEffect(() => {

        if (saved) {
            if ('not started' === ceremonyStage) {
                if (session.token) {
                    //onsole.info("About to request docs");
                    setCeremonyStage('ready for templates');
                } else {
                    //onsole.info("No session token yet");
                }
            }


            if ('ready for templates' === ceremonyStage) {
                setCeremonyStage('waiting for templates')
                //onsole.info("requesting new templates");
                prepareDocuments({
                    quote: session, token: session.token, next: (data) => {
                        //onsole.info("output from request", data);
                        setCeremonyStage('ready for tokens');
                        setChecklist('ready for tokens');
                    }
                });

            }

            if ('ready for tokens' === ceremonyStage) {
                //we have the pdf in the cloud, now we get the OneSpan token
                setCeremonyStage('waiting for tokens');
                getSignatureToken({
                    token: session.token, next: (data) => {
                        let newSession = { ...session };
                        //newSession.signatureToken = data.token;
                        //.log("Got token data", data);
                        setChecklist('ready for signature');
                        newSession.signatureInfo = data.signatureInfo;
                        if (data.signatureInfo) {
                            newSession.packageId = data.signatureInfo.packageId;
                        }
                        setSession(newSession);
                        setTimeout(() => {
                            setCeremonyStage('ready for signature');
                        }, 1000)
                    }
                });

            }
        }
    }, [ceremonyStage, session.token, saved]);




    return (
        <>
            <Container fluid>
                {'ready for signature' === ceremonyStage ?
                    <>
                        <SignatureBox />
                    </>
                    :
                    <>
                        <AlmostThere ceremonyStage={checklist} />
                    </>

                }
            </Container>

        </>
    )
}


const AlmostThere = ({ ceremonyStage }) => {

    const stages = useMemo(() => {

        let captions = ['Validating your profile', 'Preparing a customized PDF package', 'Initializing the e-signature process'];
        let ceremonyStages = ['not started', 'ready for tokens', 'ready for signature'];
        const getStageItems = () => {
            let items = [];
            let position = -1;
            for (let i = 0; i < captions.length; i++) {
                if (ceremonyStage === ceremonyStages[i]) {
                    position = i;
                }
            }
            for (let i = 0; i < captions.length; i++) {
                let item = {
                    caption: captions[i],
                    spinning: ceremonyStage === ceremonyStages[i],
                    checked: ceremonyStage !== ceremonyStages[i] && position > i,
                    variant: ceremonyStage === ceremonyStages[i] ? 'warning' : false
                };
                item.variant = item.spinning ? "info" : (item.checked ? "success" : "");
                items.push(item);
            }
            return items;
        }

        return getStageItems();

    }, [ceremonyStage]);



    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className="display-4 fw-bold lh-1 mb-3">Almost there!</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    We are getting everything ready for you. Please hold on, this should take less than a minute.
                </Col>
            </Row>

            <Row className="mt-4">
                <Col className="col-12">
                    <ListGroup>
                        {stages.map((stage, index) => (
                            <ListGroup.Item variant={stage.variant} key={index}>{stage.caption}
                                {stage.spinning ?
                                    <Spinner className="float-end" animation="grow" size="sm" />
                                    :
                                    <>{stage.checked &&
                                        <FaCheck className="float-end" />
                                    }
                                    </>
                                }

                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>

        </Container>

    )

}





