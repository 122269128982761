import * as config from '../config';
import { sendRequest } from './restClient';

const query = ({ uri, token, next, verbose }) => {

    let params = {
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: verbose
    }


    let success = sendRequest(params);

    return success;

}

const save = ({ uri, data, token, next, verbose }) => {

    let params = {
        data: data,
        method: 'post',
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: verbose
    }

    let success = sendRequest(params);

    return success;

}


export const continueSession = ({ quote, next, verbose }) => {


    const callbackTasks = (data) => {

        data.quote.token = data.token;
        sessionStorage.setItem("session", JSON.stringify(data.quote));

        if (verbose) {
            console.info("Continuing session data", data);
        }

        if (next) {
            next(data.quote);
        }
    }

    let success = save({
        data: quote,
        next: callbackTasks,
        uri: 'continue',
        verbose: verbose
    });

    return success;

}

export const createSession = ({ quote, next, verbose }) => {


    const callbackTasks = (data) => {

        sessionStorage.setItem("session", JSON.stringify(data));

        if (verbose) {
            console.info("Session data", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = save({
        data: quote,
        next: callbackTasks,
        uri: 'sessions',
        verbose: verbose
    });

    return success;

}

export const getDownloadLink = async ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Download link", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'approve/download',
        verbose: verbose
    });

    return success;

}


export const getGeoLocation = ({ next, verbose }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Geo data", data);
        }

        if (next) {
            next(data);
        }
    }

    // no auth token required on this one
    let success = query({
        next: callbackTasks,
        uri: 'geo',
        verbose: verbose
    });

    return success;

}

export const getMessages = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Messages", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'messages',
        verbose: verbose
    });

    return success;

}

export const getReferenceData = ({ token, next, verbose }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Reference data", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'data',
        verbose: verbose
    });

    return success;

}


export const getSignatureToken = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Signature token", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'signatures',
        verbose: verbose
    });

    return success;

}


export const prepareDocuments = ({ quote, token, next, verbose }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Documents prepared", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = save({
        token: token,
        data: quote,
        next: callbackTasks,
        uri: 'signatures',
        verbose: verbose
    });

    return success;

}

export const requestApproveApplication = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Approve application", data);
        }

        if (next) {
            next(data);
        }
    }



    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'approve/application',
        verbose: verbose
    });

    return success;

}



export const requestSignatureAudit = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Signature audit", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'signatures/audit',
        verbose: verbose
    });

    return success;

}


export const saveSession = ({ quote, token, next, verbose }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Session saved", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = save({
        token: token,
        data: quote,
        next: callbackTasks,
        uri: 'quotes',
        verbose: verbose
    });

    return success;

}


export const sendEmailLinkRequest = async ({ quote, token, verbose, next }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Sent link", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = save({
        token: token,
        data: quote,
        next: callbackTasks,
        uri: 'approve/email',
        verbose: verbose
    });

    return success;

}


export const sendEmailVerification = async ({ quote, email, token, verbose, next }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Sent", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = save({
        token: token,
        data: { quote: quote, email: email },
        next: callbackTasks,
        uri: 'email',
        verbose: verbose
    });

    return success;

}

export const sendLeadEmail = async ({ quote, token, verbose, next }) => {


    const callbackTasks = (data) => {

        if (verbose) {
            console.info("Sent link", data);
        }

        if (next) {
            next(data);
        }
    }


    let success = save({
        token: token,
        data: quote,
        next: callbackTasks,
        uri: 'lead',
        verbose: verbose
    });

    return success;

}