import { HeroLayout } from '../layouts/hero';
import { JumperLayout } from '../layouts/jumper';
import {
    asEmailAddress,
    asHyperlink,
    asPhoneNumber,
} from './contact.js';

export const customizeLayout = ({ brandingData, geoData, isMobile }) => {

    let customization = {};
    let jurisdiction = geoData && geoData.province_code ? geoData.province_code.toLowerCase() : "";

    let phoneAttribute = "phone_" + jurisdiction;
    let emailAttribute = "email_" + jurisdiction;

    if (brandingData[phoneAttribute]) {
        // we have a regional phone number
        customization.phone = asPhoneNumber({ contactInfo: brandingData[phoneAttribute] });
    } else {
        if (brandingData.phone) {
            // default
            customization.phone = asPhoneNumber({ contactInfo: brandingData.phone });
        }
    }
    if (brandingData[emailAttribute]) {
        customization.email = asEmailAddress({ contactInfo: brandingData[emailAttribute] });
    } else {
        if (brandingData.email) {
            customization.email = asEmailAddress({ contactInfo: brandingData.email });
        }
    }

    // clickable versions 

    if (customization.email) {
        customization.emailHref = asHyperlink({ contactInfo: customization.email });
    }

    if (customization.phone) {
        if (isMobile) {
            customization.phoneHref = asHyperlink({ contactInfo: customization.phone });
        }
    }

    return customization;

}

export const getInitialLayoutInfo = ({ query }) => {

    let layoutInfo = {
        name: 'HeroLayout',
        query: query,
        params: {}
    };

    //todo enable jumper layout here, ex:
    //if ("jump" == query.entry) {
    //    layoutInfo.name = "JumperLayout";
    //}

    return layoutInfo;

}

export const scrollUp = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export const UiLayouts = {
    HeroLayout,
    JumperLayout
};
