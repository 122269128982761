import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { TextControl } from '../controls/text';
import { sendEmailVerification } from '../libs/api';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const Payment = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [mustRevalidate, setMustRevalidate] = useState(false);


    useEffect(() => {

        scrollUp();
        setLayout({ currentScreen: 'payment', screenReady: false });


    }, []);

    useEffect(() => {

        if (session.email) {
            sendEmailVerification({
                quote: session.quote,
                email: session.email,
                token: session.token,
                next: (data) => {
                    //onsole.info("Email sent", data);
                }
            });
        }

    }, [session.email, session.quote, session.token]);

    const onChange = ({ id, value }) => {

        //

    };
    const validateScreen = (options) => {

        setMustRevalidate(true);

    }





    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Payment information</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} helpMessage="This is usually a 3-digit number, such as 001 for BMO." label="Bank institution identifier" field="payment_bank" placeholder="Identifier" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Branch mailing address" field="payment_address" placeholder="Mailing address" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Transit number" field="payment_transit" placeholder="Number" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Account number" field="payment_account" placeholder="Number" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl mustRevalidate={mustRevalidate} label="Withdrawal day" field="payment_day" placeholder="Day" onChange={onChange} required={true} type="number" minValue={1} maxValue={28} />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
