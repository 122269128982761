import {
    React,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    ListGroup,
    Row,
    Spinner,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import {
    getSignatureToken,
    prepareDocuments,
    requestApproveApplication,
    requestSignatureAudit,
} from '../libs/api.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n.js';

export const SignatureBox = (props) => {


    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);


    // this url will come from the OneSpan API, it is quote-specific
    const [signatureUrl, setSignatureUrl] = useState(false);

    // this helps us determine if we should show the iframe or not
    // we will increase the counter with every load event
    // once we reach 3 it means the signature is completed (onespan redirect) and the iframe can be closed
    const [iframeLoadCount, setIframeLoadCount] = useState(0);

    const [showInstructions, setShowInstructions] = useState('intro');
    //const [signed, setSigned] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {

        setLayout({ iframeVisible: "yes" });
        let uri = location.pathname;

        if ("yes" === session.final) {
            //.info("Final step!!!");
            ////navigate("/policy");
        } else {
            //.info("Not final step");
        }

    }, []);

    useEffect(() => {

        if (signatureUrl && signatureUrl.startsWith("https")) {
            // we likely have a valid url
            setIframeLoadCount(1);
        }

    }, [signatureUrl]);

    useEffect(() => {

        if (session.signatureInfo && session.signatureInfo.url) {
            let newUrl = session.signatureInfo.url;
            setSignatureUrl(newUrl);
        }

    }, [session.signatureInfo]);

    useEffect(() => {


        const onMessageReceived = (event) => {
            ////.info("Cool, we got a message", event);

            var origin = event.origin || event.originalEvent.origin;
            var data = event.data;
            ////.log(data, origin);

            switch (data) {
                case 'ESL:MESSAGE:REGISTER':
                    event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
                    break;
                case 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE':
                    //.info("READY TO MOVE ON");
                    requestSignatureAudit(
                        {
                            token: session.token, next: (data) => {
                                //.info("Audit info", data);
                            }
                        }
                    );

                    ///next step here

                    setIframeLoadCount(3);
                    setLayout({ iframeVisible: "no" });

                    // we ask approve to process the application
                    // requestApproveApplication((data) => {

                    // });

                    // if (newSession.next) {
                    //     navigate(newSession.next);
                    // }



                    break;
                default:
                    event.source.postMessage(data, origin);
                    break;
            }

        };

        window.addEventListener('message', onMessageReceived, false);

        return () => {
            window.removeEventListener('message', onMessageReceived);
        }



    }, []);




    const onFrameLoad = (e) => {

        // we expect two load events: for the ceremony URL, and for the redirect when the customer completes the process
        //.info(Date.now(), "iframe load", e);
        let newCount = iframeLoadCount + 1;
        setIframeLoadCount(newCount);

        if (2 === newCount) {
            setTimeout(() => {
                setShowInstructions(false);
            }, 5000);
            setTimeout(() => {
                setShowInstructions('complete');
            }, 7000);
        }

    }

    const giveUp = () => {
        saveAndContinue(false);
    }

    const moveForward = () => {
        saveAndContinue(true);
        // requestSignatureAudit((data) => {
        //     //.info("Audit info", data);
        // })
    }

    const saveAndContinue = (success) => {

        let newSession = { ...session };

        if (!success) {
            setSession({ outcome: "stop" });
        }

        //.info("Should request now");
        if (success) {
            // we ask approve to process the application -- moved to Policy screen
            // requestApproveApplication((data) => {
            //     setSession({ applied: "yes" });
            // });

            navigate("/policy");

        } else {
            navigate("/error");
        }

    }


    return (
        <Container fluid>
            {signatureUrl && iframeLoadCount > 0 && iframeLoadCount < 3 ?
                <Row className="text-center">
                    {'intro' === showInstructions &&
                        <p>
                            Please review every page of this document and use the e-signature button to confirm.
                        </p>
                    }
                    {'complete' === showInstructions &&
                        <><p>
                            When you are done with the e-signature, you can download a copy and then click the button to continue.
                        </p>
                            {/* <Button onClick={() => saveAndContinue(true)} variant='outline-primary'>Continue</Button> */}
                        </>
                    }
                    <iframe id="esignatureframe" loading="eager" title="Signature" width={"1000px"} height={"800px"} src={signatureUrl} onLoad={(e) => onFrameLoad(e)}></iframe>
                </Row>
                :
                <>
                    {iframeLoadCount < 3 &&
                        <p>Preparing e-signature...</p>
                    }
                </>
            }
            {iframeLoadCount >= 3 &&
                <>
                    <Row className="mt-4 mb-4">
                        <Col className="col-md-6 offset-md-3 text-center text-lg-start">
                            <Card>
                                <Card.Header>
                                    Your selection
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>{session.premium}/mo
                                    </Card.Title>
                                    <ul>
                                        <li>{asCurrency({ amount: session.facevalue })} policy</li>
                                        <li>Price guaranteed for {session.term} years.</li>
                                        {"yes" === session.rider ?
                                            <li>Premium waiver for total disability</li>
                                            :
                                            <li>No premium waiver</li>
                                        }
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col className='col-md-6 offset-md-3'>
                            <Card>
                                <Card.Header>
                                    Questions?
                                </Card.Header>
                                <Card.Body>
                                    <p><BsTelephone /> {layout.phone}</p>
                                    <p><HiOutlineMail /> {layout.email}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">

                        <Col className="col-md-12 col-lg-10 offset-lg-1 text-center">
                            <h5 className="text-center">Are you ready to proceed?</h5>
                        </Col>
                        <Col className="col-md-12 col-lg-10  offset-lg-1 text-center">
                            <div className="mt-1">
                                <Button onClick={() => moveForward()} variant="primary" size="lg">Yes, I want my policy</Button>{' '}
                                <Button onClick={() => giveUp()} variant="outline-info" size="lg">No, I'm not interested</Button>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="mt-1 mb-4">
                          <Col className="col-md-6 offset-md-3 text-center">
                              <Button onClick={() => giveUp()} variant="outline-primary" className='border-0'><u>Maybe?</u></Button>
                          </Col>
                      </Row> */}
                </>
            }
        </Container>
    );



}

