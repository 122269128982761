import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Form,
    Image,
    Row,
} from 'react-bootstrap';
import { AiOutlineLock } from 'react-icons/ai';
import {
    BsChevronDown,
    BsChevronRight,
    BsChevronUp,
    BsTelephone,
} from 'react-icons/bs';
import { FaRegWindowClose } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { WiMoonAltThirdQuarter } from 'react-icons/wi';
import {
    Link,
    Outlet,
    useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import { calculateScenarios } from '../libs/insurance.js';
import { HeroDesktopLayout } from './hero-desktop.jsx';
import { HeroMobileLayout } from './hero-mobile.jsx';

export const HeroLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            {layout.isDesktop ?
                <HeroDesktopLayout MyOutlet={MyOutlet} />
                :
                <HeroMobileLayout MyOutlet={MyOutlet} />
            }
        </>
    );

}
