// config for link-shopper
export const assets = "https://trn2-cmf-assets.link-nonprod.wawanesalife.com";
export const gateway = "https://cmf-shopper-api.link-nonprod.wawanesalife.com";
export const brokerConfig = {
  "authorized_mb": "yes", "authorized_sk": "yes", "authorized_qc": "no", "authorized_nb": "yes", "authorized_ns": "yes","enabled": "yes", "authorized_on": "yes", "authorized_ab": "yes", "authorized_bc": "yes", "split": 0.5,
  "name": "cmf",
  "broker": "cmf",
  "display": "cmf",
  "topic": "arn:aws:sns:ca-central-1:965766005558:trn2-cmf-admin"
};
export const brokerBranding = {
  "cmf-shopper.link-nonprod.wawanesalife.com": {
    "a": {
      "environment": "trn2",
      "website": "https://clarencemainfinancials.com",
      "broker": "cmf",
      "accessibilityLink": "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
      "split": "0.5",
      "themeSwitcher": "show",
      "ftcLink": "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
      "privacyLink": "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
      "email": "team@clarencemainfinancials.com",
      "approve_company": "Link Platform",
      "g4": "G-2QM64W4BM4",
      "approve_user": "link_cmf",
      "introduction": "SF1CFpZI1tY",
      "phone": "(204) 555-4321",
      "ab": "a",
      "logo": "a.png",
    },
    "b": {
      "environment": "trn2",
      "website": "https://clarencemainfinancials.com",
      "broker": "cmf",
      "accessibilityLink": "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
      "split": "0.5",
      "themeSwitcher": "show",
      "ftcLink": "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
      "privacyLink": "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
      "email": "team@clarencemainfinancials.com",
      "approve_company": "Link Platform",
      "g4": "G-2QM64W4BM4",
      "approve_user": "link_cmf",
      "introduction": "SF1CFpZI1tY",
      "phone": "(204) 555-4321",
      "ab": "b",
      "logo": "b.png",
    },
  },
  "trn2-cmf-app.link-nonprod.wawanesalife.com": {
    "a": {
      "environment": "trn2",
      "website": "https://clarencemainfinancials.com",
      "broker": "cmf",
      "accessibilityLink": "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
      "split": "0.5",
      "themeSwitcher": "show",
      "ftcLink": "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
      "privacyLink": "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
      "email": "team@clarencemainfinancials.com",
      "approve_company": "Link Platform",
      "g4": "G-2QM64W4BM4",
      "approve_user": "link_cmf",
      "introduction": "SF1CFpZI1tY",
      "phone": "(204) 555-4321",
      "ab": "a",
      "logo": "a.png",
    },
    "b": {
      "environment": "trn2",
      "website": "https://clarencemainfinancials.com",
      "broker": "cmf",
      "accessibilityLink": "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
      "split": "0.5",
      "themeSwitcher": "show",
      "ftcLink": "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
      "privacyLink": "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
      "email": "team@clarencemainfinancials.com",
      "approve_company": "Link Platform",
      "g4": "G-2QM64W4BM4",
      "approve_user": "link_cmf",
      "introduction": "SF1CFpZI1tY",
      "phone": "(204) 555-4321",
      "ab": "b",
      "logo": "b.png",
    },
  },
};
